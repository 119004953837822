@import "../../css-config/mixins.scss";


.discountSell{
    width: 100%;
    .container{
        padding: 40px;
        max-width: 1300px;
        margin: 0 auto;
        @include sm{
            // padding: 10px;
            margin-right:-20px ;
            padding: 10px;
        }
      
            h2::after {
                content: "";
                display: block;
                width: 50px;
                height: 5px;
                background-color: blueviolet;
                border-radius: 5px;
                margin-bottom: 50px;
              }
        
        .discount_product{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 20px;
            @include sm{
                gap: 10px;
            }

            .discount_product_item{
                width: calc(25% - 40px);
                @include md {
                    width: calc(50% - 20px);
                  }
                  @include sm{
                    width: calc(25% - 10px);
                  }
            }
        }
        button:disabled{
            cursor: auto;
        }

    }
}
