@import "../../css-config/mixins.scss";
.ProductSkeleton{
    width: 100%;
    .container{
        padding: 2rem;
        max-width: 1330px;
        margin: 0 auto;
        @include md{
            padding: 10px 20px;
            flex-direction: column;
            width: 100%;
        }
        @include sm{
            padding: 10px 10px;
        }
        .products{
            display: flex;
            gap: 1rem;
            flex-direction: row;
            flex-wrap: wrap;
            .product_basis{
                flex-basis: 180px;
                flex-grow: 1;
                max-width: 200px;
                @include sm{
                    flex-basis: 140px;
                }
            }
        }
    }
}
