
@import "../../css-config/mixins.scss";

.relatedProduct {
  width: 100%;
  padding: 1rem;
  width: 100%;
  // margin: 100px 0;
  margin-left: 8px;
  .product_container {
    max-width: 1300px;
    // padding: 1rem;
    
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    padding-right: 20px;
    @include sm {
      gap: 10px
    }
  }
}
