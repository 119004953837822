.Home{
    .loader{
        position: fixed;
        background-color: rgba(0, 0, 0, 0.397);
        z-index: 200;
        width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    }
    .payment-banner{
        max-width: 1300px;
        margin: 0 auto;
        margin-top: 100px;
        img{
            width: 100%;
        }
    }
}