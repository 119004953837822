@import "../../css-config/mixins.scss";
.cart {
  width: 100%;
  .container {
    position: fixed;
    z-index: 100;
    max-width: 1380px;
    margin: 0 auto;
    
    .cartContainer {
      width: 100%;
      height: 100vh;
      background-color: black;
      .cartContainerSlider {
        position: fixed; 
        overflow-y: auto;
        top: 0;
        right: 0;
        background-color: white; 
        height: 100vh;
        width: 25%;
        min-width: 260px;
        animation-name: slideFromRight;
        animation-duration: 0.7s;
        animation-timing-function: ease;
        background-color: rgb(238, 238, 238);
        @include md{
            width: 100%;
            height: 100vh;
        }
        .cart_header {
          border-bottom: 3px solid rgba(128, 128, 128, 0.377);
          padding-left: 0 1rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 10px;
          .cart_close {
            color: red;
            font-weight: bolder;
            div {
              cursor: pointer;
              display: flex;
              flex-direction: row;
              gap: 0.5rem;
              align-items: center;
              flex-wrap: wrap;
            }
          }
        } 
        .cart_body{
          overflow-y: auto;
          .cart_empty{
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction:column;
            align-items: center;

            margin: 5rem 0;
            color: rgba(0, 0, 0, 0.267);
            font-size: 7rem;
            h3{
              font-size: 1.25rem;
              margin-top: 0;

            }
          }
          .cart_product{

          }
        }
      }
    }
  }
}
@keyframes slideFromRight {
    from {
      transform: translateX(100%);
    }
    to{
      transform: translateX(0%);
    }
}