@import "../../css-config/mixins.scss";

.filtered_product{
    width: 100%;
    cursor: pointer;
    .filtered_product_container{
        width: 80%;
        padding: 10px 40px;
        max-width: 1300px;
        margin: 0 auto;
        border-bottom: 1px solid rgba(128, 128, 128, 0.274);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        gap: 0.5rem;
        @include md{
            padding: 5px 20px;
        }
        h4, p{
            margin: 5px 0;
        }
        .filtered_product_image{
            width: 100px;
            img{
                width: 100%;
            }
        }
     }
}