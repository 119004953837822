@import "../../css-config/mixins.scss";
$primary-color: #4CAF50;
$secondary-color: #333;
$modal-padding: 20px;
    .category_product_content {
        box-shadow: inset 0 0 0 2000px rgba(255, 0, 149, 0.856);
        background-blend-mode: overlay;
        cursor: pointer;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        width: calc(25% - 20px);
        border-radius: 10px;
        height: 100%;
        cursor: pointer;
        @include md {
          width: calc(50% - 20px);
        }
        a{
          text-decoration: none;
          color: black;
        }
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
          object-fit: cover;
        }
        .product_description {
          padding: 0 15px;
          h3{
            margin: 10px 0 0 0;
            color: blue;
          }
          p{
            margin: 5px 0 15px 0;
           }
        }
        button {
          background-color: $primary-color;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: transform 0.2s ease-in-out;
          
          &:hover {
            background-color: darken($primary-color, 10%);
          }
          
          &:active {
            transform: translateY(2px);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          }
        }
        
        .modal {
          display: none;
          position: fixed;
          z-index: 1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.5);
          
          &-content {
            background-color: white;
            margin: 10% auto;
            padding: $modal-padding;
            border: 1px solid $secondary-color;
            width: 80%;
            max-width: 800px;
            border-radius: 5px;
            animation: fade-in 0.3s ease-in-out;
          }
          
          .close {
            color: $secondary-color;
            float: right;
            font-size: 28px;
            font-weight: bold;
            cursor: pointer;
          }
        }
        
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
