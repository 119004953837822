.Brand {
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: center;
  font-family: "Rajdhani", sans-serif;
  .bg-gray {
    background-color: rgba(190, 190, 190, 0.288);
  }

  .title {
    margin-bottom: 50px;
  }

  .image_wrapper {
    padding: 1rem;
    margin: 0px 30px;
    img{
      max-height: 5rem;
    }
  }

  .image_wrapper img {
    width: 80%;
  }
}
