@import "../../css-config/mixins.scss";
.payment{
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
    @include md{
        margin-top: 50px;
    }
    .container{
        max-width: 1330px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .payment_bkash{
            width: 100px;
            margin-top: 2rem;            
        }
        .content{
            background-color: #e2638b;
            padding: 1rem;
            color: white;
            font-size: .9rem;
            line-height: 1.5rem;
            max-width: 400px;
            border-radius: 10px;
            span{
                color: rgb(255, 238, 0);
                font-weight: 600;
            }
            p::after{
                content:"";
                display:block;
                width: 100%;
                height: 1px;
                margin-top: 5px;
                background-color: #c2305e;
            }
        }
        
        .box{
            // padding: 2rem;
            min-height: 70vh;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            .box-inline{
                display: inline-block;
                background-color: rgb(226, 43, 128);
                color: white;
                padding: 10px 20px;
            }
            .input-box{
                display: flex;
                flex-direction: column;
                gap:1rem;
                input{
                    border: none;
                    font-size: .9rem;
                    padding: 10px;
                    min-width: 70%;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                }
            }
            .box-content{
                display: flex;
                align-items: center;
                gap: 1rem;
            
                justify-content: space-between;
                img{
                    max-height: 3rem;
                }
            }
        }
    }
    .payment-button{
        background: #ffa2c083;
        border: 1px solid white;
        padding: 0.5rem;
        color: white;
        font-size: 1.25rem;
        cursor: pointer;
    }
}