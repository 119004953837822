@import "../../css-config/mixins.scss";

.article{
    width: 100%;
    margin-top: 80px;
    font-family: "Rajdhani", sans-serif;
    word-spacing: 0.2rem;
    @include md{
        margin-top: 50px;
    }
    .container{
        max-width: 1300px;
        min-height: 100vh;
        padding: 30px;
        margin: 0 auto;
        background-color: #EFF0F5;
        span{
            color: blue;
            text-decoration: underline;
            cursor: pointer;
            font-size: 1.5rem;
        }
        .top_section{
            gap: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            img{
                margin-top: 20px;
                max-width: 60%;
                @include md{
                    max-width: 100%;
                }
            }
            @include md{
                flex-direction: column;
                
            }
        }

        .description{
            margin-top: 30px;
            background-color: white;
            padding: 10px;
            color: gray;
            font-size: 1.25rem;
            line-height: 40px;
            border-radius: 20px;
            letter-spacing: 1px;
            p{
                padding: 20px;
            }
        }
    }
}