.Modal{
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.507);
    position: fixed;
    left: 0;
    top: 0;
    .Container{
        width: 70%;
        max-width: 500px;
        padding: 10px;
        border-radius: 10px;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 40px;
        .content{
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            img{
                width: 80%;
                object-fit: cover;
               
            }

        }
    }
}