@import "../../css-config/mixins.scss";
$border-color: #ddd;
.OrderItem{
    margin: 30px 0;
    h2{
        font-size: 20px;
        @include sm{
            font-size: 16px;
        }
    }
    h3{
       font-size: 20px;
       @include sm{
        font-size: 16px;
       } 
    }
    th{
        font-size: 18px;
        @include sm{
            font-size: 14px;
        }
    }
    .status{
        display: inline-block;
        padding: 10px 20px;
        color: white;
        letter-spacing: 2px;
    }
    table {
        border-collapse: collapse;
        width: 100%;
       th, td {
        border: 1px solid $border-color;
        padding: 8px;
        text-align: left;
        .table-img{
            width: 70px;
            @include sm{
                width: 50px;
            }
        }
        span{
            margin: 0;
           cursor: pointer; 
        }
       }
       
       th {
        background-color: #f2f2f2;
       }
       @include sm{
            margin: 20px 0;
       }
    }
    .hr{
        margin: 70px;
    }
}