@import "../../css-config/mixins.scss";

.ProfileOrderTable{
    font-size: 16px;
    @include xsm{
        font-size: 12px;
    }
    img{
        width: 80px;
        @include xsm{
            width: 60px;
        }
    }
}