@import "../../css-config/mixins.scss";
 
.searchFiled{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    z-index: 999;
    background-color: white;
    transform: translateY(100%);
    animation: slideSearchWindow 0.5s ease forwards;

    .searchFieldContainer{
        max-width: 1300px;
        padding: 0 40px;
        margin: 0 auto;
        @include md{
            padding: 0 20px;
        }
        .inputContent{
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 3px solid rgba(128, 128, 128, 0.466);
            span{
                font-size: 2rem;
                color: rgba(128, 128, 128, 0.466);
                cursor: pointer;
            }
            input{
                display: flex;
                align-items: center;
                width: 90%;
                margin: 0 auto;
                border: none;
                padding: 10px;

                outline: none;
                font-size: 3rem;
                opacity: 20%;
                text-align: center;
                font-weight: bolder;
                @include md{
                    font-size: 2.5rem;
                }
                @include sm{
                    font-size: 2.25rem;
                }
            }
        }
    }
}
@keyframes slideSearchWindow {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}