@import "../../css-config/mixins.scss";

.Practice{
  color: black;
  box-shadow: inset 0 0 0 2000px rgba(255, 0, 149, 0.856);
  background-blend-mode: overlay;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: calc(25% - 20px);
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
  @include xmd{
    width: calc(33% - 20px);
  }
  @include md {
    width: calc(50% - 20px);
  }
  .image{      
    width: 100%;
    height: 250px;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    
    &:hover{
      .secondImg{
        z-index: 2;
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .mainImg{
      z-index: 1;
    }

    span{
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: white;
      color: teal;
      padding: 3px 5px;
      z-index: 3;
      font-weight: 500;
      font-size: 12px;
    }
  }

  h3{
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin-left: 15px;
  }

  .prices{
    margin-left: 5px;
    gap: 20px;
    padding: 0 10px;
    .original{
      font-size: 16px;
      
      color: black;
      &:first-child{
        color: gray;
        text-decoration: line-through;
      }
    }
  }

}
   