@import "../../css-config/mixins.scss";
.checkoutForm{
    padding-bottom: 3rem;
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin-bottom: 20px;
        @include sm{
            grid-template-columns: 1fr;
        }
       }
       
       .input-row {
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin-bottom: 20px;
       }
       
       label {
        font-weight: bold;
        margin-bottom: 5px;
       }

       input {
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 5px;
       }
       
       button[type="submit"] {
        padding: 10px 20px;
        font-size: 18px;
        color: white;
        background-color: #333;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
       
        &:hover {
           background-color: #555;
        }
       }
}