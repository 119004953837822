@import "../../css-config/mixins.scss";

.product_view {
  width: calc(20% - 20px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  a {
    text-decoration: none;
    color: black;
  }
  cursor: pointer;
  @include md {
    width: calc(50% - 20px);
  }
  .product_img {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    .product_stock{
      content: "STOCK OUT";
      position: absolute;
      z-index: 100;
      background-color: red;
      color: white;
      font-family: "Rajdhani", sans-serif;
      padding: 5px 15px;
      font-weight: 700;
    }
    img {
      width: 100%;
      object-fit: cover;
      &:hover {
        transform: scale(1.3);
        transition: 0.5s;
      }
    }
  }

  .product_regular{
    font-family: "Rajdhani", sans-serif;
    color: gray;
    margin-top: -15px;
    margin-bottom: -15px;
    font-weight: 500;
    text-decoration: line-through;
    font-size: 17px;
    @include md{
      font-size: 14px;
    }
  }
  .product_description {
    padding: 0 10px;
    .product_name{
      font-weight: 700;
      margin: 15px 0;
      @include md {
        font-size: 1.16rem;
      }
    }
    h3 {
      color: gray;
      font-family: "Rajdhani", sans-serif;
      font-weight: 500;
      font-size: 1.25rem;
    }
  }
  .cartButton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    button {
      cursor: pointer;
      border-radius: 5px;
      color: white;
      background-color: orange;
      border: none;
      padding: 8px 15px;
      font-size: 1rem;
      @include md {
        padding: 6px 16px;
        border-radius: 3px;
        font-size: 0.9rem;
      }
    }
  }
  .product_price {
    color: orangered;
    font-style: italic;
    font-weight: 600;
    font-size: 1rem;
    @include md{
      font-size: 0.8rem;
      margin-top: 1rem;
 }
    span{
      color: gray;
      font-family: "Rajdhani", sans-serif;
      font-size: 0.7rem;
    }
  }
 
}
