@mixin sm {
    @media only screen and (max-width: 640px) {
        @content;
    }
}
@mixin xsm {
    @media only screen and (max-width: 480px) {
        @content;
    }
}

@mixin md {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin xmd {
    @media only screen and (max-width: 990px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin xl {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin xxl {
    @media only screen and (max-width: 1536px) {
        @content;
    }
}
