.preloader{
    height: 100vh;
    width: 100%;
    background-color: #2E3190;
    position: relative;
    z-index: 2000000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    .texts-container{
        display: flex;
        gap: 2%;
        font-size: 1.75rem;
        font-weight: 600;
        font-family:Georgia, 'Times New Roman', Times, serif;
        span{
            letter-spacing: 2px;
        }
        .preloader_the{
            transform: rotate(-90deg);
            margin-right: -45px;
        }
        .preloader_group{
            display:flex; flex-direction:column; margin-top:20px
        }
        .preloader_number{
            font-size: 6rem;
            font-weight: 100;
            margin-top: -10px;
            color: rgba(240, 248, 255, 0.548);
        }
    }
    
}