@import "../../css-config/mixins.scss";

.category {
  width: 100%;
  margin-top: 0px;
  .container {
    padding: 0 40px;
    max-width: 1300px;
    margin: 0 auto;

    h2::after {
      content: "";
      display: block;
      width: 50px;
      height: 5px;
      background-color: blueviolet;
      border-radius: 5px;
      margin-bottom: 50px;
    }
    .category_product {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 20px;

// all product
      .category_product_content {
        box-shadow: inset 0 0 0 2000px rgba(255, 0, 149, 0.856);
        background-blend-mode: overlay;
        cursor: pointer;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        width: calc(25% - 20px);
        border-radius: 10px;
        height: 100%;
        cursor: pointer;
        @include md {
          width: calc(50% - 20px);
        }
        img {
          width: 100%;
          object-fit: cover;
        }
        .product_description {
          padding: 0 15px;
          h3{
            margin: 10px 0 0 0;
            color: blue;
          }
          p{
            margin: 5px 0 15px 0;
           }
        }
      }
    }
  }
}
@keyframes hoverShadow {
  from{
    background-color: white;
  }
}