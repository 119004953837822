.adminSidebar{
    color: white;
    letter-spacing: 1.5px;
    font-weight: 400;
    font-size: 1rem;
    padding: 0 0.5rem;
    .container{
        .content{
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            border-bottom: 1px solid black;
            .icon{
                font-size: 1.25rem;
                font-weight: bold;
            }
        }
    }
}