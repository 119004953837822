@import "../../css-config/mixins.scss";

.mans {
  width: 100%;
  .mans_banner {
    height: 50vh;
    background-position: center;
    background-size: cover;
    // max-width: 1330px;
    margin: 0 auto;
    margin-top: 80px;
    @include md{
      margin-top: 50px;
    }
    @include lg {
      height: 70vh;
    }
    @include md {
      height: 60vh;
    }
    @include sm {
      height: 45vh;
    }
    @include xsm {
      height: 30vh;
    }
  }
  .mans_container{
    max-width: 1300px;
    margin: 0 auto;
    @include sm{
      padding: 10px;
      margin-right:-20px ;
  }
    .discount_product{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 20px;

      .discount_product_item{
          width: calc(25% - 40px);
          @include md {
              width: calc(50% - 20px);
            }
      }
  }
  }
}
