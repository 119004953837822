@import "../../css-config/mixins.scss";
.Profile{
    width: 100%;
    margin-top: 80px;
    @include md{
        margin-top: 50px;
    }
    .container{
        max-width: 1330px;
        margin: 0 auto;
        .heading{
            font-weight: bold;
            text-align: center;
            font-size: 2rem;
            padding: 10px 30px;
            background-color: blueviolet;
            color: white;
        }
    }
}