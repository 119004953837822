@import "../../css-config/mixins";
.checkoutItem{
    width: 100%;
    .container{
        max-width: 1300px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        img{
            width: 100px;
        }
        .removeBtn{
            cursor: pointer;
            border: none;
            background-color: red; 
            padding: 10px;
            border-radius: 5px;
            color: white;
            font-weight: bold;
            font-size: 1rem;
            @include sm{
                font-size: .75rem;
                padding: 8px;
            }
        }
    }
    .cartItemList{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        // div{
        //     display: flex;
        //     flex-direction: row;
        //     align-items: center;
        //     gap: 1rem;
        // }
    }
}