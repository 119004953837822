@import "../../css-config/mixins.scss";

.productDetails {
  width: 100%;
  margin: 80px 0;

  @include md {
    margin-top: 50px;
  }
  @include sm {
  }
  .productDetails_container {
    max-width: 1300px;
    margin: 0 auto;
    .product_display {
      // margin-top: 100px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      align-items: start;
      @include md {
        flex-direction: column;
      }
.image_container{
   flex: 12;

  .display_image {
    
    margin-right: 0;
    @include md {
      margin: 0 20px;
      // margin-right: 40px;
      display: flex;
      flex-direction: column-reverse;
    }
    display: flex;
    .side-image{
      display: flex;
      flex-direction: column;
      @include md{
        display: flex;
        flex-direction: row;
      }
      img{
        // height: 100px;
        width: 80px;
        object-fit: cover;
        padding: 10px 10px 0px 0;
        cursor: pointer;
        @include sm {
          width: 70px;
        }
        @include xsm {
          width: 50px;
        }
      }
    }
    .main-image{
      img {
      margin-top: 10px;
      // height: 400px;
      object-fit: cover;
      // width: 100%;
      max-width: unset !important;
      box-shadow: rgba(136, 165, 191, 0.288) 6px 2px 16px 0px,
        rgba(255, 255, 255, 0.288) -6px -2px 16px 0px;
    }
    }
  }
}
      .display_content {
        flex: 12;
        padding: 2rem;
        p{
          line-height: 2rem;
          letter-spacing: .08rem;
          font-style: italic;
        }
        .displayContent_action {
          display: flex;
          gap: 1rem;
          padding-bottom: 2rem;
          border-bottom: 2px solid rgba(128, 128, 128, 0.205);
        }
        .product_category_and_share {
          div {
            display: flex;
            align-items: center;
            gap: 0.8rem;
          }
        }
        .display_content_top{
          font-family: "Rajdhani", sans-serif;
          color: rgba(0, 0, 0, 0.884);
          span{
            font-weight: 500;
            color: red;
            text-decoration: line-through;
          }
          h2{
            margin-bottom: 10px;
          }
          h3{
            margin-top: 10px;
            margin-bottom: 30px;
          }
        }
      }
    }
    .product_heading {
      margin-top: 50px;
      margin-left: 10px;
    }
  }
  .details_description{
    color: rgb(51, 51, 51);
    word-spacing: 0.25rem;
    font-family: "Rajdhani", sans-serif;
    font-size: 1.15rem;
  }
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .productDetails_stockOut{
    width: 100%;
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
    color: red;
    border: 1px solid red; 
    background-color: rgba(255, 0, 0, 0.096);
    font-size: 1.5rem;
    text-align: center;
    padding: 0.5rem;
  }
}
