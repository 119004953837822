@import "../../../css-config/mixins.scss";
.DetailsSkeleton{
    max-width: 1330px;
    margin: 0 auto;
    .container{
        padding: 10px 40px;
        flex-wrap: wrap;
        display: flex;
        gap: 1rem;
        @include md{
            padding: 10px 20px;
            flex-direction: column;
            width: 100%;
        }
        @include sm{
            padding: 10px 10px;

        }
        .imageSection{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            gap: 1rem;
            @include sm{
                gap: 0;
            }
            .sideImage{
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1rem;
                flex: 2;
            }
            .mainImage{
                flex: 8;
                width: 100%;
                height: 500px;
                @include sm{
                    height: 300px;
                }
            }
            
        }
        .textSection{
            width: 100%;
            margin-top: 3rem;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}