.cart_container{
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
    gap: 1.25rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.123);
    .cart_product_image{
        width: 35%;
        box-shadow: rgba(149, 157, 165, 0.116) 0px 8px 24px;
        img{
            width: 100%;
        }
    }
    .cart_product_details{
        h4{
            margin: 5px;
        }
        div{
            margin: 5px 0;
        }
        h5{
            margin: 0;
            color: blueviolet;
            span{
                font-weight: black;
                font-size: 1.25rem;
            }
        }
    }
}