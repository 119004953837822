@import "../../css-config/mixins.scss";

.checkoutPage{
    width: 100%;
    margin-top: 80px;
    @include md{
        margin-top: 60px;
    }
    .container{
        max-width: 1300px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: space-around;
        .checkoutCart {
            max-width: 600px;
            
            padding: 20px;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
           }
           
           h1 {
            font-size: 28px;
            margin-bottom: 20px;
           }
           @include lg{
            flex-direction: column-reverse;
            align-items: center;
           }
    }
}