.slideStyles  {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;

  };
  .turn-icon {
    div {
      background-color: teal;
      padding: 0 14px;
      border-radius: 50%;
      font-size: 2rem;
    }
  }
  
  .rightArrowStyles  {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -6%;
    font-size: 45px;
    color: #fff;
    z-index: 1;
    cursor: pointer;
  };
  
  .leftArrowStyles  {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: -6%;
    font-size: 45px;
    color: #fff;
    z-index: 1;
    cursor: pointer;
  };
  
  .sliderStyles  {
    position: relative;
    cursor: pointer;
    height: 100%;
  };
  
  .dotsContainerStyles  {
    display: flex;
    justify-content: center;
  };
  
  .dotStyle  {
    margin: 0 3px;
    cursor: pointer;
    font-size: 20px;
    color: teal
  };
  
  .slider-container{
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    background-color: white;
    color: teal;
    padding: 3px 5px;
    z-index: 3;
    font-weight: 500;
    font-size: 12px;
  }
  .slider-discount{
    position: absolute;
    right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    color: white;
    background-color: teal;
    padding: 3px 5px;
    z-index: 3;
    font-weight: 500;
    font-size: 12px;
  }
  .slider-description{
    height: 300px;
    position: relative;
    .slider-content{
      font-size: 14px;
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 10px;
      bottom: 10px;
      background-color: rgba(0, 128, 128, 0.658);
      width: calc(100% - 30px);
      color: white;
      padding: 5px;
      .discount_price{
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }