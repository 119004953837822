*{
    scroll-behavior: smooth;
}
.App{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}
a{
    text-decoration: none;
    color: white;
}
.error{
    color:red;
    font-weight: bold;
    align-items: center;
}
.load-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    button{
        border: none;
        background: none;
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        font-family: "Rajdhani", sans-serif;
        padding: 15px 50px;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.2rem;
        border-radius: 10px;
    }
}