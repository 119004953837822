.Blogs{
    width: calc(100% - 60px);
    padding: 30px;
    .container{
        max-width: 1300px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .blogs-post{
            color: blueviolet;
            font-weight: bold;
        }
        .blogs-heading{
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 50px;
        }
    }
    .blogs-section{
        display: flex;
        flex-direction: row;
        gap: 7%;
        flex-wrap: wrap;
    }
}