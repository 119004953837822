@import "../../css-config/mixins.scss";
 
.accessories{
    width: 100%;
    .accessories_banner {
        height: 50vh;
        background-position: center;
        background-size: cover;
        // max-width: 1330px;
        margin: 0 auto;
        margin-top: 80px;
        @include md{
          margin-top: 50px;
        }
        @include lg {
            height: 70vh;
          }
          @include md {
            height: 60vh;
          }
          @include sm {
            height: 45vh;
          }
          @include xsm {
            height: 30vh;
          }
      }
      .accessories_container{
        max-width: 1300px;
        margin: 0 auto;
        @include sm{
          padding: 10px;
          margin-right:-20px ;
      }
      }
    .discount_product{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;
  
        .discount_product_item{
            width: calc(25% - 40px);
            @include md {
                width: calc(50% - 20px);
              }
        }
    }
    .container{
        max-width: 1300px;
        margin: 0 auto;
        .accessories_header{
            display: flex;
            flex-direction: row;
            gap: 20px;
            
            .accessories_banner{
                background-image: url('https://www.replacealens.com/v/images/Accessories/Eyeglasses-Accessories.jpg');
                height: 48vh;
                width: calc(64% - 20px);
                background-size: cover;
                background-position: center;
            }
            .accessories_top_content{
                display: flex;
                flex-direction: row;
                .accessories_content{
                    background-image: url('https://www.shutterstock.com/image-illustration/glasses-lenses-contact-3d-illustration-260nw-1956545419.jpg');
                    width: 100%;
                    height: 24vh;
                    background-position: center;
                    background-size: cover;
                    flex-grow: 1;
                    flex-shrink:1 ;
                }
            }
        }
    }
}
