.Control {
  h1{
    margin-top: 70px;
    color: blueviolet;
    text-align: center;
  }
  .container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
}
