@import "../../css-config/mixins.scss";

.showcase{
    width: 100%;
    margin-top: 20px;
    font-family: "Rajdhani", sans-serif;
    .showcase_container{
        max-width: 1300px;
        margin: 0 auto; 
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px;
        @include xsm{
            flex-direction: column;
        }
        .showcase_container_image{
            width: calc(25% - 10px);
            cursor: pointer;
            overflow: hidden;
            position: relative;
            z-index: 0;
            img{
                height: 150px;
                @include sm{
                    height: 120px;
                }
                @include xsm{
                    height: 100px;
                }
            }
            @include md{
                width: calc(50% - 10px);
                margin: auto;
            }
            @include xsm{
                width: calc(100% - 10px);
                margin: auto;
            }
            
            .showcase_productName{
                padding-left: 10px;
                width: 50px;
                position: absolute;
                z-index: 99;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                 text-transform: uppercase;
                 color: white;
                 &:hover{
                        scale: 1.1;
                        transition: 0.8s;
                 }
                 h1{
                    font-size: 2rem;
                    @include sm{
                        font-size: 1.25rem;
                    }
                 }
            }
            img{
                 width: 100%;
                 object-fit: cover;
                 &:hover{
                    scale: 1.4;
                    transition: 0.8s;
                 }
            }
        }
    }
}

