@import "../../../css-config/mixins.scss";
$primary-color: #3a7bd5;

.login{
    margin-top: 10%;
    .container {
        width: 500px;
        min-width: 310px;
        margin: 50px auto;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

        @include md {
            width: 300px;
          }
    }
    
    h1 {
        text-align: center;
        margin-bottom: 20px;
    }
    
    .input-field {
        margin-bottom: 20px;
    
        label {
            display: block;
            margin-bottom: 5px;
        }
    
        input {
            min-width: 300px;
           width: 95%;
            padding: 10px;
            font-size: 16px;
            border: 1px solid #ddd;
            border-radius: 5px;
        }
    }
    .button-field {
        text-align: center;
    
        button {
            background-color: $primary-color;
            color: #fff;
            padding: 10px 20px;
            font-size: 16px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
    
            &:hover {
                background-color: darken($primary-color, 10%);
            }
        }
    }
}
