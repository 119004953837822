@import "../../css-config/mixins.scss";
 
.productLayout { 
  width: 100%;
  margin-top: 0px; 
  .container {
    padding: 0 40px;
    max-width: 1300px;
    margin: 0 auto;

    h2::after {
      content: "";
      display: block;
      width: 50px;
      height: 5px;
      background-color: blueviolet;
      border-radius: 5px;
      margin-bottom: 50px;
    }
    .productLayout_product {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 20px;
    }
  }
}
@keyframes hoverShadow {
  from{
    background-color: white;
  }
}