@import "../../css-config/mixins.scss";

.header {
  width: 100%;
  position: fixed;
  z-index: 10000;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  @include md{
    margin-bottom: 50px;
  }

  .header_content{
    // max-width: 2200px;
    margin: 0 auto;
    // display: block;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    box-shadow: 0 4px 2px 0px rgba(99, 99, 99, 0.2);
    @include md {
      height: 50px;
      padding: 0 20px;
      padding-left: 0;      
    }
  }

  .header_container {
    position: fixed;
    z-index: 99;
    width: 100%;
    background: #212121;
    background: white;
    // max-width: 1900px;
    margin: 0 auto;
    padding: 0 40px;
    @include sm{
      padding: 0 10px;
    }
   
    .header_link {

      display: flex;
      a {
        text-decoration: none;
        color: white;
        padding: 0 25px;
        font-weight: 600;
      } 
      @include sm{
        display: none;
      }
    }
    .header_name {
      img{
        height: 60px;
        @include md{
          height: 40px;
        }
      }
      
    }
    .header_shopping {
      display: flex;
      flex-direction: row;
      margin-right: 40px;
      @include md{
        margin: 0;
      }
      span{
        color: black;
        font-weight: bold;
        margin: 0 5px;
        cursor: pointer;
      }
      div {
        cursor:pointer;
        color: gray;
        padding: 0 10px;
        font-weight: 700;
        font-size: 1.35rem;
        position: relative;
        &:last-child::after {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: rgb(43, 86, 226);
          position: absolute;
          top: -3px;
          right: 5px;
        }
      }
      .bar{
        cursor: auto;
        margin-left: 5px;
      }
      .register{
        @include sm{
          display: none;
        }
      }
      .subMenu{
        font-size: 1rem;
        font-weight: 500;
        position: absolute;
        list-style-type: none;
        display: none;
        right: -70px;
        // top: 30px;
        li{
          background-color: rgba(0, 0, 0, 0.733);
          color:white;
          padding: 15px 25px;
          margin-right: 30px;
        }
      }
      .heart:hover .subMenu{
        display: block;
      }
    }
  }
}


