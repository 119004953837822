@import "../../css-config/mixins.scss";
$primary-color: #3a7bd5;
$secondary-color: #9dc4fc;

.register-section {
  padding: 0;
  padding-top: 3%;
  background-color: #EFF0F5;
  .container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    
    @include md {
      width: 300px;
    }
    p{
      margin: 0;
    }
    .google{
      background-color: rgb(255, 145, 0);
      padding: 10px;
      color: white;
      font-weight: 600;
      cursor: pointer
    }
  }

  h2 {
    text-align: center;
    margin-top: 10px 0;
  }

  .input-field {
    margin-bottom: 10px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input {
      width: 95%;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  }

  .button-field {
    text-align: center;

    button {
      background-color: $primary-color;
      color: #fff;
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($primary-color, 15%);
      }
    //   &:disabled {
    //     background-color: $secondary-color;
    //   }
    button[disabled] {
                background-color: $secondary-color;

       }
    }
  }
}
