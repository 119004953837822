@import "../../css-config/mixins.scss";

.banner {
  width: 100%;
  margin-top: 80px;
  @include md{
    margin-top: 50px;
  }
  .image {
    // max-width: 2200px;
    height: 82vh;
    width: auto;
    margin: 0 auto;
    background-image: url('../../img/thespectacle23-cover.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include lg {
      height: 70vh;
    }
    @include md {
      height: 60vh;
    }
    @include sm {
      height: 45vh;
    }
    @include xsm {
      height: 30vh;
    }
  }
}
