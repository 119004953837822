$border-color: #ddd;
.productForm{
    $primary-color: #220aff;
    .container {
        max-width: 600px;
        margin: 30px auto;
        padding: 20px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    }
    .upload-btn{
        background-color: $primary-color;
        padding: 10px 25px;
        color: white;
        border: none;
        font-weight: 600;
        cursor: pointer;
        border-radius: 5px;
        margin-top:20px ;
        display: block;
    }
    
    h1 {
        color: $primary-color;
        text-align: center;
    }
    
    label {
        display: block;
        margin-top: 15px;
        margin-bottom: 5px;
        span{
            color: red
        };
    }
    select{
        padding: 8px 3rem;
        font-weight: bold;
        border: none;
        background-color: #e2e2e2;
        border-radius: 0;

    }
    .gender{
        margin-top: 20px;
    }
 
    input[type="text"],
    textarea,
    input[type="number"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 1.15rem;
    }
    
    input[type="file"] {
        margin-top: 10px;
    }
    .gender-checkbox{
        display: flex;
        flex-direction: row;
        gap: 3rem;
    }
    .product-flex{
        display: flex;
        gap: 10px;
    }
    .imageUpload{
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
    }
    .imageUpload__bg{
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.055);
        width: 360px;
    }
    .imageUpload_container{
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        gap: 1rem;
    }
    .image_size{
        width: 5rem;
        height: 5rem;
        border-radius: 10px;
    }
    .imageUpload_input{
        opacity: 0;
    }
    .imageUpload__label{
        display: flex;
        cursor: pointer;
        flex-direction: column;
    }
    
    .imageUpload__label__div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .imageUpload__label__image{
        width: 3rem;
        height: 3rem;
        color: rgba(128, 128, 128, 0.404);
    }
    .imageUpload__text{
        color:rgba(8, 8, 8, 0.274);
        font-weight: 500;
    }
}