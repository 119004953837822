@import "../../css-config/mixins.scss";

.CallButton {
  width: 100%;
  // height: 100vh;
  position: relative;
  .facebook{
    z-index: 100;
    margin: 0;
    position: fixed;
    top: 50%;
    right: -45px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transform: rotate(-90deg);
    background: blue;
    color: white;
    padding: 10px 30px;
    font-weight: 700;
    cursor: all-scroll;
    @include sm{
      padding: 5px 20px;
      right: -40px;
    }
  }
  .callAnimation {
    height: 60px;
    width: 60px;
    background-color: #008080;
    border-radius: 50%;
    position: fixed;
    z-index: 100;
    bottom: 30px;
    left: 30px;
    right: 30px;
    .animate {
      position: absolute;
      height: inherit;
      width: inherit;
      background-color: #008080;
      animation: circleAnimate 2s ease-in-out infinite;
      border-radius: 50%;
      opacity: 0.8;
    }
    .second {
      animation-delay: 1s;
    }

    @keyframes circleAnimate {
      100% {
        opacity: 0;
        transform: scale(1.5);
      }
    }
  }

  a {
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 2.25rem;
  }
}
